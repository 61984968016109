<template>
  <body>
    <!-- Spinner Start -->
    <!-- <div
      id="spinner"
      class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
    >
      <div class="spinner-grow text-success" role="status"></div>
    </div> -->
    <!-- Spinner End -->

    <!-- Carousel Start -->
    <div class="container-fluid p-0 mb-5">
      <div id="header-carousel" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="w-100" src="img/carousel-1.jpg" alt="Image" />
            <div class="carousel-caption">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-7 pt-5">
                    <h1 class="display-4 text-white mb-3 animated slideInDown">
                      LiPAN NIGERIA
                    </h1>
                    <p class="fs-5 text-white-50 mb-5 animated slideInDown">
                      Livestock Producers Association of Nigeria (LiPAN)
                    </p>
                    <a
                      class="btn btn-success py-2 px-3 animated slideInDown"
                      href=""
                    >
                      Learn More
                      <div
                        class="d-inline-flex btn-sm-square bg-white text-success rounded-circle ms-2"
                      >
                        <i class="fa fa-arrow-right"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <img class="w-100" src="img/carousel-2.jpg" alt="Image" />
            <div class="carousel-caption">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-7 pt-5">
                    <h1 class="display-4 text-white mb-3 animated slideInDown">
                      Livestock Investment Summit 2024
                    </h1>
                    <p class="fs-5 text-white-50 mb-5 animated slideInDown">
                      Unlocking Nigeria's Livestock Potential: Strategies for
                      Sustainable Growth and Competitiveness
                    </p>
                    <a
                      class="btn btn-success py-2 px-3 animated slideInDown"
                      href=""
                    >
                      Learn More
                      <div
                        class="d-inline-flex btn-sm-square bg-white text-success rounded-circle ms-2"
                      >
                        <i class="fa fa-arrow-right"></i>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#header-carousel"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
    <!-- Carousel End -->

    <!-- About Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div class="row g-5">
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              class="position-relative overflow-hidden h-100"
              style="min-height: 400px"
            >
              <img
                class="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/about-1.jpg"
                alt=""
                style="object-fit: cover"
              />
              <img
                class="position-absolute top-0 end-0 bg-white ps-2 pb-2"
                src="img/about-2.png"
                alt=""
                style="width: 200px; height: 200px"
              />
            </div>
          </div>
          <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="h-100">
              <div
                class="d-inline-block rounded-pill bg-secondary text-success py-1 px-3 mb-3"
              >
                Livestock Investment Summit 2024
              </div>
              <h1 class="display-6 mb-5">
                Unlocking Nigeria's Livestock Potential: Strategies for
                Sustainable Growth and Competitiveness
              </h1>
              <div
                class="bg-light border-bottom border-5 border-success rounded p-4 mb-4 text-lg"
              >
                <span class="text-success h4">
                  <i class="fa fa-map-marker-alt"></i> Transcorp Hilton
                  Abuja</span
                >
                <p class="mt-4 text-success h4">
                  <i class="fa fa-calendar"></i> November, 13th & 14th 2024
                </p>
              </div>

              <!-- <p class="mb-5">
                For General Inquiries and Registration:
              </p> -->
              <!-- <a class="btn btn-success py-2 px-3 me-3" href="">
                Learn More
                <div
                  class="d-inline-flex btn-sm-square bg-white text-success rounded-circle ms-2"
                >
                  <i class="fa fa-arrow-right"></i>
                </div>
              </a> -->
              <a class="btn btn-outline-success py-2 px-3" href="/contact">
                Contact Us
                <div
                  class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                >
                  <i class="fa fa-arrow-right"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- About End -->

    <!-- Causes Start -->
    <div class="container-xxl bg-light my-5 py-5" v-show="false">
      <div class="container py-5">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 500px"
        >
          <div
            class="d-inline-block rounded-pill bg-secondary text-success py-1 px-3 mb-3"
          >
            Feature Causes
          </div>
          <h1 class="display-6 mb-5">
            Every Child Deserves The Opportunity To Learn
          </h1>
        </div>
        <div class="row g-4 justify-content-center">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div
              class="causes-item d-flex flex-column bg-white border-top border-5 border-success rounded-top overflow-hidden h-100"
            >
              <div class="text-center p-4 pt-0">
                <div
                  class="d-inline-block bg-success text-white rounded-bottom fs-5 pb-1 px-3 mb-4"
                >
                  <small>Education</small>
                </div>
                <h5 class="mb-3">Education For African Children</h5>
                <p>
                  Tempor erat elitr rebum at clita dolor diam ipsum sit diam
                  amet diam et eos
                </p>
                <div class="causes-progress bg-light p-3 pt-2">
                  <div class="d-flex justify-content-between">
                    <p class="text-dark">
                      $10,000 <small class="text-body">Goal</small>
                    </p>
                    <p class="text-dark">
                      $9,542 <small class="text-body">Raised</small>
                    </p>
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="90"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="position-relative mt-auto">
                <img class="img-fluid" src="img/courses-1.jpg" alt="" />
                <div class="causes-overlay">
                  <a class="btn btn-outline-success" href="">
                    Read More
                    <div
                      class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                    >
                      <i class="fa fa-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div
              class="causes-item d-flex flex-column bg-white border-top border-5 border-success rounded-top overflow-hidden h-100"
            >
              <div class="text-center p-4 pt-0">
                <div
                  class="d-inline-block bg-success text-white rounded-bottom fs-5 pb-1 px-3 mb-4"
                >
                  <small>Pure Water</small>
                </div>
                <h5 class="mb-3">Ensure Pure Drinking Water</h5>
                <p>
                  Tempor erat elitr rebum at clita dolor diam ipsum sit diam
                  amet diam et eos
                </p>
                <div class="causes-progress bg-light p-3 pt-2">
                  <div class="d-flex justify-content-between">
                    <p class="text-dark">
                      $10,000 <small class="text-body">Goal</small>
                    </p>
                    <p class="text-dark">
                      $9,542 <small class="text-body">Raised</small>
                    </p>
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="90"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="position-relative mt-auto">
                <img class="img-fluid" src="img/courses-2.jpg" alt="" />
                <div class="causes-overlay">
                  <a class="btn btn-outline-success" href="">
                    Read More
                    <div
                      class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                    >
                      <i class="fa fa-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div
              class="causes-item d-flex flex-column bg-white border-top border-5 border-success rounded-top overflow-hidden h-100"
            >
              <div class="text-center p-4 pt-0">
                <div
                  class="d-inline-block bg-success text-white rounded-bottom fs-5 pb-1 px-3 mb-4"
                >
                  <small>Healthy Life</small>
                </div>
                <h5 class="mb-3">Ensure Medical Treatment</h5>
                <p>
                  Tempor erat elitr rebum at clita dolor diam ipsum sit diam
                  amet diam et eos
                </p>
                <div class="causes-progress bg-light p-3 pt-2">
                  <div class="d-flex justify-content-between">
                    <p class="text-dark">
                      $10,000 <small class="text-body">Goal</small>
                    </p>
                    <p class="text-dark">
                      $9,542 <small class="text-body">Raised</small>
                    </p>
                  </div>
                  <div class="progress">
                    <div
                      class="progress-bar"
                      role="progressbar"
                      aria-valuenow="90"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <span>90%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="position-relative mt-auto">
                <img class="img-fluid" src="img/courses-3.jpg" alt="" />
                <div class="causes-overlay">
                  <a class="btn btn-outline-success" href="">
                    Read More
                    <div
                      class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                    >
                      <i class="fa fa-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Causes End -->

    <!-- Service Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 500px"
        >
          <div
            class="d-inline-block rounded-pill bg-secondary text-success py-1 px-3 mb-3"
          >
            What We Do
          </div>
          <h1 class="display-6 mb-5">Learn More What We Do And Get Involved</h1>
        </div>
        <div class="row g-4 justify-content-center">
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
              <img class="img-fluid mb-4" src="img/icon-1.png" alt="" />
              <h4 class="mb-3">
                Financial sustainability in the livestock sector
              </h4>
              <p class="mb-4">
                Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum
                diam justo sed vero dolor duo.
              </p>
              <a class="btn btn-outline-success px-3" href="">
                Learn More
                <div
                  class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                >
                  <i class="fa fa-arrow-right"></i>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
              <img class="img-fluid mb-4" src="img/icon-2.png" alt="" />
              <h4 class="mb-3">Share Experience and Researches</h4>
              <p class="mb-4">
                Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum
                diam justo sed vero dolor duo.
              </p>
              <a class="btn btn-outline-success px-3" href="">
                Learn More
                <div
                  class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                >
                  <i class="fa fa-arrow-right"></i>
                </div>
              </a>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="service-item bg-white text-center h-100 p-4 p-xl-5">
              <img class="img-fluid mb-4" src="img/icon-3.png" alt="" />
              <h4 class="mb-3">Dialogue and Discussions</h4>
              <p class="mb-4">
                Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum
                diam justo sed vero dolor duo.
              </p>
              <a class="btn btn-outline-success px-3" href="">
                Learn More
                <div
                  class="d-inline-flex btn-sm-square bg-success text-white rounded-circle ms-2"
                >
                  <i class="fa fa-arrow-right"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Service End -->

    <!-- Donate Start -->
    <div
      class="container-fluid donate my-5 py-5"
      data-parallax="scroll"
      data-image-src="img/carousel-2.jpg"
    >
      <div class="container py-5">
        <div class="row g-5 align-items-center">
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div
              class="d-inline-block rounded-pill bg-secondary text-success py-1 px-3 mb-3"
            >
              Contact Us
            </div>
            <h1 class="display-6 text-white mb-5">
              For General Inquiries and Registration:
            </h1>
            <p class="text-white-50 mb-0">
              <a href="mailto:support@lipannigeria.com" class="text-white"
                ><i class="fa fa-envelope me-2"></i>support@lipannigeria.com</a
              >
            </p>

            <p class="text-white-50 mb-0">
              <a href="tel:08036246311" class="text-white"
                ><i class="fa fa-phone me-2"></i>08036246311,</a
              >
              <a href="tel:08039441999" class="text-white">08039441999,</a>
              <a href="tel:08064892904" class="text-white">08064892904</a>
            </p>
          </div>
          <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div class="h-100 bg-white p-5">
              <form>
                <div class="row g-3">
                  <div class="col-12">
                    <h1 class="display-6 text-black mb-5">Register Now</h1>

                    <div class="col-12">
                      <div class="btn-group d-flex justify-content-around">
                        <!-- <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        checked
                      />
                      <label class="btn btn-light py-3" for="btnradio1"
                        >Exhibitor</label
                      > -->

                        <!-- <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio2"
                      />
                      <label class="btn btn-light py-3" for="btnradio2"
                        >Sponsor</label
                      > -->
                      </div>
                    </div>
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control bg-light border-0"
                        id="name"
                        placeholder="Your Name"
                      />
                      <label for="name"> Full Name</label>
                    </div>

                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control bg-light border-0"
                        id="name"
                        placeholder="Your Name"
                      />
                      <label for="name"> Organization Name</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-floating">
                      <input
                        type="email"
                        class="form-control bg-light border-0"
                        id="email"
                        placeholder="Your Email"
                      />
                      <label for="email">Organization Email</label>
                    </div>
                  </div>

                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control bg-light border-0"
                      id="name"
                      placeholder="Your Name"
                    />
                    <label for="name"> Phone Number</label>
                  </div>

                  <div class="col-12">
                    <button class="btn btn-success px-5" style="height: 60px">
                      Submit
                      <div
                        class="d-inline-flex btn-sm-square bg-white text-success rounded-circle ms-2"
                      >
                        <i class="fa fa-arrow-right"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Donate End -->

    <!-- Team Start -->
    <div class="container-xxl py-5">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 500px"
        >
          <div
            class="d-inline-block rounded-pill bg-secondary text-success py-1 px-3 mb-3"
          >
            Team Members
          </div>
          <h1 class="display-6 mb-5">Let's Meet With Our Management</h1>
        </div>
        <div class="row g-4">
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
            <div class="team-item position-relative rounded overflow-hidden">
              <div class="overflow-hidden">
                <img class="img-fluid" src="img/inname.jpeg" alt="" />
              </div>
              <div class="team-text bg-light text-center p-4">
                <h5>Dr. Mohammad Ali Inname </h5>
                <p class="text-success">Secretary General</p>
                <div class="team-social text-center">
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
            <div class="team-item position-relative rounded overflow-hidden">
              <div class="overflow-hidden">
                <img class="img-fluid" src="img/unknown.jpeg" alt="" />
              </div>
              <div class="team-text bg-light text-center p-4">
                <h5>Full Name</h5>
                <p class="text-success">Designation</p>
                <div class="team-social text-center">
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
            <div class="team-item position-relative rounded overflow-hidden">
              <div class="overflow-hidden">
                <img class="img-fluid" src="img/unknown.jpeg" alt="" />
              </div>
              <div class="team-text bg-light text-center p-4">
                <h5>Full Name</h5>
                <p class="text-success">Designation</p>
                <div class="team-social text-center">
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
            <div class="team-item position-relative rounded overflow-hidden">
              <div class="overflow-hidden">
                <img class="img-fluid" src="img/unknown.jpeg" alt="" />
              </div>
              <div class="team-text bg-light text-center p-4">
                <h5>Abdulrahman Yabo</h5>
                <p class="text-success">ICT Coordinator</p>
                <div class="team-social text-center">
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-facebook-f"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-twitter"></i
                  ></a>
                  <a class="btn btn-square" href=""
                    ><i class="fab fa-instagram"></i
                  ></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Team End -->

    <!-- Testimonial Start -->
    <div class="container-xxl py-5" v-show="false">
      <div class="container">
        <div
          class="text-center mx-auto mb-5 wow fadeInUp"
          data-wow-delay="0.1s"
          style="max-width: 500px"
        >
          <div
            class="d-inline-block rounded-pill bg-secondary text-success py-1 px-3 mb-3"
          >
            Testimonial
          </div>
          <h1 class="display-6 mb-5">Trusted By Millions Of People</h1>
        </div>
        <div
          class="owl-carousel testimonial-carousel wow fadeInUp"
          data-wow-delay="0.1s"
        >
          <div class="testimonial-item text-center">
            <img
              class="img-fluid bg-light rounded-circle p-2 mx-auto mb-4"
              src="img/testimonial-1.jpg"
              style="width: 100px; height: 100px"
            />
            <div class="testimonial-text rounded text-center p-4">
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. Magna ut diam sit et amet stet eos sed
                clita erat magna elitr erat sit sit erat at rebum justo sea
                clita.
              </p>
              <h5 class="mb-1">Doner Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
          <div class="testimonial-item text-center">
            <img
              class="img-fluid bg-light rounded-circle p-2 mx-auto mb-4"
              src="img/testimonial-2.jpg"
              style="width: 100px; height: 100px"
            />
            <div class="testimonial-text rounded text-center p-4">
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. Magna ut diam sit et amet stet eos sed
                clita erat magna elitr erat sit sit erat at rebum justo sea
                clita.
              </p>
              <h5 class="mb-1">Doner Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
          <div class="testimonial-item text-center">
            <img
              class="img-fluid bg-light rounded-circle p-2 mx-auto mb-4"
              src="img/testimonial-3.jpg"
              style="width: 100px; height: 100px"
            />
            <div class="testimonial-text rounded text-center p-4">
              <p>
                Clita clita tempor justo dolor ipsum amet kasd amet duo justo
                duo duo labore sed sed. Magna ut diam sit et amet stet eos sed
                clita erat magna elitr erat sit sit erat at rebum justo sea
                clita.
              </p>
              <h5 class="mb-1">Doner Name</h5>
              <span class="fst-italic">Profession</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Testimonial End -->

    <!-- Back to Top -->
    <a href="#" class="btn btn-lg btn-success btn-lg-square back-to-top"
      ><i class="bi bi-arrow-up"></i
    ></a>
  </body>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  components: {
    //   // HelloWorld
  },
  data () {
    return {}
  }
}
</script>
